import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 30 min. `}<meta itemProp="cookTime" content="PT30M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole wheat linguine, 1 box (16 oz.)</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  4 large eggs, 2 egg yolks (or 2 jumbo eggs, 2 egg yolks)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan cheese, 2/3 cup grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bacon, 12-16 oz.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colander</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">2 cup measuring cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Pasta scoop</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Cook pasta according to box instructions. Set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While pasta is cooking, whisk eggs, parmesan, salt, pepper, and cayenne in large bowl. Transfer to measuring cup.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Once pasta is done, cook bacon until just brown and crispy.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add pasta to bacon and mix.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Transfer pasta and bacon to mixing bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pour egg mixture over pasta and mix.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      